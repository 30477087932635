/* eslint-disable max-lines */
import gsap from 'gsap';

let _this: IntroCanvas;

/**
 * позиция фигуры относительно холста (макет)
 */
const translateLetterK = [
    {
        x: 190 / 1045,
        y: -540 / 1080,
    },
    {
        x: 0 / 1045,
        y: -7 / 1080,
    },
    {
        x: 512 / 1045,
        y: 349 / 1080,
    },
    {
        x: 624 / 1045,
        y: -240 / 1080,
    },
];

const translateLetterD = [
    {
        x: 373 / 1045,
        y: -220 / 1080,
    },
    {
        x: 139 / 1045,
        y: -540 / 1080,
    },
    {
        x: 0 / 1045,
        y: 23 / 1080,
    },
    {
        x: 333 / 1045,
        y: 84 / 1080,
    },
];

const translateLetterTs = [
    {
        x: 0 / 1045,
        y: 25 / 1080,
    },
    {
        x: 394 / 1045,
        y: 232 / 1080,
    },
    {
        x: 504 / 1045,
        y: -220 / 1080,
    },
    {
        x: 261 / 1045,
        y: -540 / 1080,
    },
];

const translateLetterO = [
    {
        x: 367 / 1045,
        y: 349 / 1080,
    },
    {
        x: 495 / 1045,
        y: -226 / 1080,
    },
    {
        x: 254 / 1045,
        y: -540 / 1080,
    },
    {
        x: 0 / 1045,
        y: -75 / 1080,
    },
];

// object-fit: cover;
function drawImageScaled(
    img: HTMLImageElement,
    ctx: CanvasRenderingContext2D,
    canvasWidth: number,
    canvasHeight: number,
    x = 0,
) {
    const hRatio = canvasWidth / img.width;
    const vRatio = canvasHeight / img.height;
    const ratio = Math.max(hRatio, vRatio);
    const centerShift_x = (canvasWidth - img.width * ratio) / 2 + x * ratio;
    const centerShift_y = (canvasHeight - img.height * ratio) / 2;
    ctx.drawImage(img, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
}

export default class IntroCanvas {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D | null;
    imagesIntro: NodeListOf<HTMLImageElement>;
    images: HTMLImageElement[];
    canvasWidth: number;
    canvasHeight: number;
    kLetterScale: number;
    dLetterScale: number;
    tsLetterScale: number;
    oLetterRadius: number;

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        _this = this;
        this.canvas = document.querySelector('.js-intro-canvas') as HTMLCanvasElement;
        this.ctx = this.canvas?.getContext('2d');
        this.imagesIntro = document.querySelectorAll<HTMLImageElement>('.js-intro-image');
        this.images = [];
        this.canvasWidth = window.innerWidth;
        this.canvasHeight = window.innerHeight;
        // для сохранения пропорций фигур высчитываем их новый размер
        // (высота канваса * высота фигуры) / высота канваса в макете / высота фигуры
        this.kLetterScale = (this.canvasHeight * 942) / 1080 / 942;
        this.dLetterScale = (this.canvasHeight * 1060) / 1080 / 1060;
        this.tsLetterScale = (this.canvasHeight * 1059) / 1080 / 1059;
        this.oLetterRadius = (this.canvasHeight * 942) / 1080 / 2;

        if (this.canvas) {
            let canvasWidthKoef;
            if (window.matchMedia('(min-width: 1200px)').matches) {
                canvasWidthKoef = 0.52;
            } else if (window.matchMedia('(min-width: 577px)').matches) {
                canvasWidthKoef = 0.88;
            } else {
                canvasWidthKoef = 1;
            }
            this.canvasWidth = window.innerWidth * canvasWidthKoef;
            this.canvas.width = this.canvasWidth;
            this.canvas.height = this.canvasHeight;
        }

        this.imagesIntro.forEach((image) => {
            const img = new Image();
            img.src = image?.currentSrc;
            this.images.push(img);
        });
    }

    init = () => {
        this.drawShapes(
            null,
            this.images[0],
            0,
            this.canvasWidth,
            translateLetterK[0].x,
            translateLetterK[0].y,
            translateLetterD[0].x,
            translateLetterD[0].y,
            translateLetterTs[0].x,
            translateLetterTs[0].y,
            translateLetterO[0].x,
            translateLetterO[0].y,
        );
    };

    // Буква К
    drowLetterK = (
        prevImage: HTMLImageElement | null,
        image: HTMLImageElement,
        imageX: number,
        prevImageX: number,
        xPosition: number,
        yPosition: number,
    ) => {
        if (this.ctx) {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.moveTo(
                0 * this.kLetterScale + xPosition * this.canvasWidth,
                0 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                799.979 * this.kLetterScale + xPosition * this.canvasWidth,
                0 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                450.37 * this.kLetterScale + xPosition * this.canvasWidth,
                463.311 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                450.366 * this.kLetterScale + xPosition * this.canvasWidth,
                477.114 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                815.644 * this.kLetterScale + xPosition * this.canvasWidth,
                942.222 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                0.00390625 * this.kLetterScale + xPosition * this.canvasWidth,
                942.218 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                0 * this.kLetterScale + xPosition * this.canvasWidth,
                0 * this.kLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.closePath();
            this.ctx.clip();

            this.animateImages(prevImage, image, imageX, prevImageX);
            this.ctx.restore();
        }
    };

    // Буква Д
    drowLetterD = (
        prevImage: HTMLImageElement | null,
        image: HTMLImageElement,
        imageX: number,
        prevImageX: number,
        xPosition: number,
        yPosition: number,
    ) => {
        if (this.ctx) {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.moveTo(
                297.297 * this.dLetterScale + xPosition * this.canvasWidth,
                0 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                847.411 * this.dLetterScale + xPosition * this.canvasWidth,
                0 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                847.411 * this.dLetterScale + xPosition * this.canvasWidth,
                746 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                960.003 * this.dLetterScale + xPosition * this.canvasWidth,
                746 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                959.999 * this.dLetterScale + xPosition * this.canvasWidth,
                1060.78 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                0.00274662 * this.dLetterScale + xPosition * this.canvasWidth,
                1060.78 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                0 * this.dLetterScale + xPosition * this.canvasWidth,
                746 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                134.383 * this.dLetterScale + xPosition * this.canvasWidth,
                746 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                297.297 * this.dLetterScale + xPosition * this.canvasWidth,
                0 * this.dLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.closePath();
            this.ctx.clip();

            this.animateImages(prevImage, image, imageX, prevImageX);
            this.ctx.restore();
        }
    };

    // Буква Ц
    drowLetterTs = (
        prevImage: HTMLImageElement | null,
        image: HTMLImageElement,
        imageX: number,
        prevImageX: number,
        xPosition: number,
        yPosition: number,
    ) => {
        if (this.ctx) {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.moveTo(
                725.642 * this.tsLetterScale + xPosition * this.canvasWidth,
                0 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                0 * this.tsLetterScale + xPosition * this.canvasWidth,
                0 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                0 * this.tsLetterScale + xPosition * this.canvasWidth,
                910.831 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                450.37 * this.tsLetterScale + xPosition * this.canvasWidth,
                910.831 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                450.37 * this.tsLetterScale + xPosition * this.canvasWidth,
                1058.7 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                936.296 * this.tsLetterScale + xPosition * this.canvasWidth,
                1058.7 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                936.296 * this.tsLetterScale + xPosition * this.canvasWidth,
                584.63 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                725.642 * this.tsLetterScale + xPosition * this.canvasWidth,
                584.63 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.lineTo(
                725.642 * this.tsLetterScale + xPosition * this.canvasWidth,
                0 * this.tsLetterScale + yPosition * this.canvasWidth,
            );
            this.ctx.closePath();
            this.ctx.clip();

            this.animateImages(prevImage, image, imageX, prevImageX);
            this.ctx.restore();
        }
    };

    // Буква О
    drowLetterO = (
        prevImage: HTMLImageElement | null,
        image: HTMLImageElement,
        imageX: number,
        prevImageX: number,
        xPosition: number,
        yPosition: number,
    ) => {
        if (this.ctx) {
            this.ctx.save();
            this.ctx.beginPath();
            this.ctx.arc(
                xPosition * this.canvasWidth + this.oLetterRadius, // x
                yPosition * this.canvasWidth + this.oLetterRadius, // y
                this.oLetterRadius, // radius
                0, // startAngle
                Math.PI * 2, // endAngle
            );
            this.ctx.closePath();
            this.ctx.clip();

            this.animateImages(prevImage, image, imageX, prevImageX);
            this.ctx.restore();
        }
    };

    animateImages = (
        prevImage: HTMLImageElement | null,
        image: HTMLImageElement,
        imageX: number,
        prevImageX: number,
    ) => {
        if (!this.ctx) return;
        if (prevImage) {
            drawImageScaled(
                prevImage,
                this.ctx,
                this.canvasWidth,
                this.canvasHeight,
                prevImageX,
            );
        }
        drawImageScaled(image, this.ctx, this.canvasWidth, this.canvasHeight, imageX);
    };
    drawShapes = (
        prevImage: HTMLImageElement | null,
        image: HTMLImageElement,
        imageX: number,
        prevImageX: number,
        kLetterNewX: number,
        kLetterNewY: number,
        dLetterNewX: number,
        dLetterNewY: number,
        tsLetterNewX: number,
        tsLetterNewY: number,
        oLetterNewX: number,
        oLetterNewY: number,
    ) => {
        this.clear();
        this.drowLetterK(prevImage, image, imageX, prevImageX, kLetterNewX, kLetterNewY);
        this.drowLetterD(prevImage, image, imageX, prevImageX, dLetterNewX, dLetterNewY);
        this.drowLetterTs(prevImage, image, imageX, prevImageX, tsLetterNewX, tsLetterNewY);
        this.drowLetterO(prevImage, image, imageX, prevImageX, oLetterNewX, oLetterNewY);
    };

    clear = () => {
        if (this.ctx) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    };

    animate = (
        prevImg: HTMLImageElement,
        img: HTMLImageElement,
        imageX: number,
        imageTargetX: number,
        prevImageX: number,
        prevImageTargetX: number,
        kLetterX: number,
        kLetterTargetX: number,
        kLetterY: number,
        kLetterTargetY: number,
        dLetterX: number,
        dLetterTargetX: number,
        dLetterY: number,
        dLetterTargetY: number,
        tsLetterX: number,
        tsLetterTargetX: number,
        tsLetterY: number,
        tsLetterTargetY: number,
        oLetterX: number,
        oLetterTargetX: number,
        oLetterY: number,
        oLetterTargetY: number,
    ) => {
        gsap.to(
            {
                imageRealX: imageX,
                prevImageRealX: prevImageX,
                kLetterRealX: kLetterX,
                kLetterRealY: kLetterY,
                dLetterRealX: dLetterX,
                dLetterRealY: dLetterY,
                tsLetterRealX: tsLetterX,
                tsLetterRealY: tsLetterY,
                oLetterRealX: oLetterX,
                oLetterRealY: oLetterY,
            },
            {
                duration: 1,
                ease: 'easeInOut',
                imageRealX: imageTargetX,
                prevImageRealX: prevImageTargetX,
                kLetterRealX: kLetterTargetX,
                kLetterRealY: kLetterTargetY,
                dLetterRealX: dLetterTargetX,
                dLetterRealY: dLetterTargetY,
                tsLetterRealX: tsLetterTargetX,
                tsLetterRealY: tsLetterTargetY,
                oLetterRealX: oLetterTargetX,
                oLetterRealY: oLetterTargetY,
                onUpdate: function () {
                    const imageNewX = this.targets()[0].imageRealX;
                    const prevImageNewX = this.targets()[0].prevImageRealX;
                    const kLetterNewX = this.targets()[0].kLetterRealX;
                    const kLetterNewY = this.targets()[0].kLetterRealY;
                    const dLetterNewX = this.targets()[0].dLetterRealX;
                    const dLetterNewY = this.targets()[0].dLetterRealY;
                    const tsLetterNewX = this.targets()[0].tsLetterRealX;
                    const tsLetterNewY = this.targets()[0].tsLetterRealY;
                    const oLetterNewX = this.targets()[0].oLetterRealX;
                    const oLetterNewY = this.targets()[0].oLetterRealY;
                    _this.drawShapes(
                        prevImg,
                        img,
                        imageNewX,
                        prevImageNewX,
                        kLetterNewX,
                        kLetterNewY,
                        dLetterNewX,
                        dLetterNewY,
                        tsLetterNewX,
                        tsLetterNewY,
                        oLetterNewX,
                        oLetterNewY,
                    );
                },
            },
        );
    };

    move(index: number, previousIndex: number, directionForward: boolean) {
        const letterIndex = index % 4;
        const letterPreviousIndex = previousIndex % 4;

        this.animate(
            this.images[previousIndex],
            this.images[index],
             // стартовая координата для новой картинки
            directionForward ? this.images[previousIndex].width : -this.images[previousIndex].width,
            0, // новая координата для новой картинки

            0, // стартовая координата для предыдущей картинки
            // новая координата для предыдущей картинки
            directionForward ? -this.images[previousIndex].width : this.images[previousIndex].width,

            translateLetterK[letterPreviousIndex].x,
            translateLetterK[letterIndex].x,
            translateLetterK[letterPreviousIndex].y,
            translateLetterK[letterIndex].y,

            translateLetterD[letterPreviousIndex].x,
            translateLetterD[letterIndex].x,
            translateLetterD[letterPreviousIndex].y,
            translateLetterD[letterIndex].y,

            translateLetterTs[letterPreviousIndex].x,
            translateLetterTs[letterIndex].x,
            translateLetterTs[letterPreviousIndex].y,
            translateLetterTs[letterIndex].y,

            translateLetterO[letterPreviousIndex].x,
            translateLetterO[letterIndex].x,
            translateLetterO[letterPreviousIndex].y,
            translateLetterO[letterIndex].y,
        );
    }
}

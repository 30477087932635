import { Swiper } from 'swiper';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import gsap from 'gsap';
import IntroCanvas from "./intro-canvas";

let swiperEl: Swiper | null;
let sliderContainer: HTMLElement | null;
let progressbar: HTMLElement | null | undefined;
let bgSlides: NodeListOf<HTMLElement>;

export function createNavigation(parent: HTMLElement) {
    parent.innerHTML = `
        <div class="slider-ctrls bvi-no-styles">
            <button class="slider-btn slider-btn--prev js-slide-prev bvi-no-styles" aria-label="предыдущий слайд">
                <svg class="bvi-no-styles" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.86179 4L13.1382 10.4M13.1382 10.4L6.86179 17M13.1382 10.4H12.4889"
                        stroke="white" stroke-width="1.23807" />
                </svg>
            </button>
            <button class="slider-btn slider-btn--next js-slide-next bvi-no-styles" aria-label="следующий слайд">
                <svg class="bvi-no-styles" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.86179 4L13.1382 10.4M13.1382 10.4L6.86179 17M13.1382 10.4H12.4889"
                        stroke="white" stroke-width="1.23807" />
                </svg>
            </button>
        </div>
    `;
}

// фиксим то, что clip-path обрезается при скроле
let plusWidth = true;
let sliderContainerWidth: number | undefined;
const triggerReflow = () => {
    if (!sliderContainer || !sliderContainerWidth) return;

    sliderContainer.style.width = plusWidth
        ? `${sliderContainerWidth - 0.0001}px`
        : `${sliderContainerWidth + 0.0001}px`;
    plusWidth = !plusWidth;
};

// определяем направление скрола. Если скролим вниз, то функцию не запускаем
let prevScrollTop = document.documentElement.scrollTop;
const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop;

    if (prevScrollTop > scrollTop) triggerReflow();
    prevScrollTop = scrollTop;
};

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            window.addEventListener('scroll', onScroll);

            if (swiperEl && swiperEl.autoplay) {
                swiperEl.autoplay.start();

                setTimeout(() => {
                    progressbar?.classList.add('is-active');
                }, 1);
            }
        } else {
            window.removeEventListener('scroll', onScroll);

            if (swiperEl && swiperEl.autoplay) {
                swiperEl.autoplay.stop();
                progressbar?.classList.remove('is-active');
            }
        }
    });
});

function createBgSlides(bgContainer: HTMLElement, slidesLength: number) {
    for (let i = 0; i < slidesLength; i++) {
        const bgSlide = document.createElement('div');
        bgSlide.classList.add('intro-section__bg-slide', 'js-intro-slider-bg-slide');
        bgContainer.appendChild(bgSlide);
    }
    bgSlides = bgContainer.querySelectorAll('.js-intro-slider-bg-slide');
}

function init(container: HTMLElement | Document = document) {
    const introSlider = container.querySelector<HTMLElement>('.js-intro-slider');
    if (!introSlider) return;

    sliderContainer = introSlider.closest('.js-intro-slider-container');
    const slides = introSlider.querySelectorAll('.swiper-slide');

    // сохраняем соотношение сторон, чтобы ровно вставала маска
    const koef = 0.5625;
    let windowWidth = window.innerWidth;
    const setSliderSize = () => {
        if (!introSlider) return;

        const windowHeight = window.innerHeight;
        windowWidth = window.innerWidth;
        sliderContainerWidth = sliderContainer?.offsetWidth;

        if (windowHeight / windowWidth > koef) {
            introSlider.style.height = `${windowHeight}px`;
            introSlider.style.width = `${windowHeight / koef}px`;
        } else {
            introSlider.style.height = `${windowWidth * koef}px`;
            introSlider.style.width = `${windowWidth}px`;
        }
    };

    setSliderSize();

    const onResize = () => {
        if (windowWidth !== window.innerWidth) setSliderSize();
    };

    window.addEventListener('resize', onResize);

    if (slides.length > 1) {
        const textBlocks = sliderContainer?.querySelectorAll<HTMLElement>('.js-intro-slider-content');
        const bgContainer = sliderContainer?.querySelector<HTMLElement>('.js-intro-slider-bg');
        const sliderTrigger = document.querySelector<HTMLElement>('.js-intro-slider-trigger');
        if (bgContainer) createBgSlides(bgContainer, slides.length);

        const navigation = sliderContainer?.querySelector<HTMLElement>('.js-slider-navigation');
        const countPagination = sliderContainer?.querySelector<HTMLElement>('.js-slider-pagination');
        if (navigation) createNavigation(navigation);
        const prevBtn = navigation?.querySelector<HTMLElement>('.js-slide-prev');
        const nextBtn = navigation?.querySelector<HTMLElement>('.js-slide-next');
        const autoplayDuration = 7000;

        const introCanvas = new IntroCanvas();

        if (sliderTrigger) observer.observe(sliderTrigger);

        swiperEl = new Swiper(introSlider, {
            modules: [Pagination, Navigation, Autoplay],
            speed: 1000,
            pagination: {
                el: countPagination,
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return (
                        '<span class="text-2xl intro-ctrl__current ' +
                        currentClass +
                        '"></span>' +
                        '<div class="intro-ctrl__progressbar js-slider-progressbar bvi-no-styles"></div>' +
                        '<span class="text-2xl intro-ctrl__current ' +
                        totalClass +
                        '"></span>'
                    );
                },
            },
            navigation: {
                nextEl: nextBtn,
                prevEl: prevBtn,
            },
            loop: true,
            autoplay: {
                delay: autoplayDuration,
                disableOnInteraction: false,
                waitForTransition: false,
            },
            on: {
                init: () => {
                    progressbar = sliderContainer?.querySelector<HTMLElement>('.js-slider-progressbar');
                    if (sliderContainer) {
                        sliderContainer.style.setProperty('--autoplay-duration', `${autoplayDuration}ms`);
                    }

                    introCanvas.init();

                    progressbar?.classList.remove('is-active');
                    setTimeout(() => {
                        progressbar?.classList.add('is-active');
                    }, 1);

                    if (textBlocks) {
                        textBlocks.forEach((block) => block.classList.remove('is-active'));
                        textBlocks[0].classList.add('is-active');
                    }

                    if (bgSlides) {
                        bgSlides.forEach((block) => block.classList.remove('is-active'));
                        bgSlides[0].classList.add('is-active');
                        const tl = gsap.timeline();

                        tl.set(bgSlides, {
                            xPercent: 100,
                        }).set(bgSlides[0], {
                            xPercent: 0,
                        });
                    }

                    // проверяем, ушел ли пользователь с текущей вкладки, чтобы приостановить автоплей
                    handleVisibilityChange();
                    document.addEventListener('visibilitychange', handleVisibilityChange);
                },
                slideChange: (swiper) => {
                    progressbar?.classList.remove('is-active');
                    setTimeout(() => {
                        progressbar?.classList.add('is-active');
                    }, 1);

                    if (textBlocks) {
                        setTimeout(() => {
                            textBlocks?.forEach((block) => block.classList.remove('is-active'));
                            setTimeout(() => {
                                textBlocks[swiper.realIndex].classList.add('is-active');
                            }, 500);
                        }, 1);
                    }

                    if (bgSlides) {
                        const tl = gsap.timeline();

                        let directionForward =
                            swiper.realIndex > (swiper as any).previousRealIndex ||
                            (swiper.realIndex === 0 && (swiper as any).previousRealIndex === slides.length - 1);

                        if (swiper.realIndex === slides.length - 1 && (swiper as any).previousRealIndex === 0)
                            directionForward = false;

                        introCanvas.move(swiper.realIndex, (swiper as any).previousRealIndex, directionForward);

                        tl.fromTo(
                            bgSlides[(swiper as any).previousRealIndex],
                            {
                                xPercent: 0,
                            },
                            {
                                xPercent: directionForward ? -100 : 100,
                                duration: 1,
                            },
                        ).fromTo(
                            bgSlides[swiper.realIndex],
                            {
                                xPercent: directionForward ? 100 : -100,
                            },
                            {
                                xPercent: 0,
                                duration: 1,
                            },
                            0,
                        );
                    }
                },
            },
        });

        function handleVisibilityChange() {
            if (document.visibilityState === 'visible') {
                if (swiperEl && swiperEl.autoplay) {
                    swiperEl.autoplay.start();

                    progressbar?.classList.remove('is-active');
                    setTimeout(() => {
                        progressbar?.classList.add('is-active');
                    }, 1);
                }
            } else {
                if (swiperEl && swiperEl.autoplay) {
                    swiperEl.autoplay.stop();
                }
            }
        }
    }
}

const _module = { init };

export default _module;
